import { icon } from "@fortawesome/fontawesome-svg-core";

let timelineElements = [
    {
        id: 1,
        title: "ExploreWorld-Unity",
        location: "Niagara Falls, ON",
        description: "Building a world exploration game with real world physics.",
        buttonText: "Source Code",
        date: "May 2022 - June 2022",
        icon: "",
        buttonLink: "https://github.com/SurkhiSyed/Final-ExploreWorld",
        isWorkIcon: true,
    },
    {
        id: 2,
        title: "WorldTreeSweeper-Unity",
        location: "Niagara Falls, ON",
        description: "Building a solo-type RPG game with stimulating challenges.",
        buttonText: "Source Code",
        date: "May 2023 - June 2023",
        icon: "",
        buttonLink: "https://github.com/SurkhiSyed/WorldTreeSweeper-Unity",
        isWorkIcon: true,
    },
    {
        id: 3,
        title: "MetEmployed",
        location: "Remote",
        description: "Creating a job search platform for targeted audience.",
        buttonText: "Devpost",
        date: "August 2023",
        icon: "",
        buttonLink: "https://devpost.com/software/soluhacks",
        isWorkIcon: false,
    },
    {
        id: 4,
        title: "BioSafe3D",
        location: "Toronto, ON",
        description: "Redesigning 3D printers to be more efficient and eco-friendly.",
        buttonText: "Check it out",
        date: "September 2023 - December 2023",
        icon: "",
        buttonLink: "https://drive.google.com/drive/folders/1s56gmrxlXBasoJM-iDi_LU_zjG3toEDf?usp=sharing",
        isWorkIcon: true,
    },
    {
        id: 5,
        title: "DinoNFT",
        location: "Waterloo, ON",
        description: "Simple web3 dino running game with blockchain wallet integration.",
        buttonText: "Source Code",
        date: "May 2024",
        icon: "",
        buttonLink: "https://github.com/SurkhiSyed/Hack",
        isWorkIcon: false,
    },
    {
        id: 6,
        title: "Inclusee",
        location: "Toronto, ON",
        description: "Ensuring enhanced visibility for digital content viewers.",
        buttonText: "Devpost",
        date: "August 2024",
        icon: "",
        buttonLink: "https://devpost.com/software/inclusee",
        isWorkIcon: false,
    },
    {
        id: 7,
        title: "Vireel",
        location: "Remote",
        description: "Making news trendy and engaging for all audiences.",
        buttonText: "Source Code",
        date: "August 2024 - Present",
        icon: "",
        buttonLink: "https://github.com/SurkhiSyed/Vireel",
        isWorkIcon: false,
    }
]

export default timelineElements;